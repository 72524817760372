//tool tip atom

$a-tool-tip--font-size: 14px;
$a-tool-tip--padding: 5px 10px;
$a-tool-tip--font-color: $color-gray !default;
$a-tool-tip--background: $body-color;


.a-tool-tip {
  position: relative;
  z-index: 1;
  backface-visibility: hidden;

  &:hover {
    .a-tool-tip__content{
      opacity: 1;
      visibility: visible;
    }
    .a-tool-tip__content--right{
      opacity: 1;
      visibility: visible;
    }
  }
}

.a-tool-tip__content {
  @include set-font-size($a-tool-tip--font-size, 1.2);
  //display: none;
  visibility: hidden;
  position: absolute;
  z-index: 10;
  bottom: calc(100% + 5px);
  left: 0;
  padding: $a-tool-tip--padding;
  background: $a-tool-tip--background;
  color: $a-tool-tip--font-color;
  //white-space: nowrap;
  box-shadow:  0 5px 20px 0 rgba(19, 19, 19, 0.25);
  opacity: 0;
  font-weight: $font-weight--normal;
  transition: opacity 0.3s ease-in-out;

  @include rtl-direction {
    right: 0;
    left: auto;
  }

  &::before {
    @include css-triangle(8px, $a-tool-tip--background, bottom, ture);
    //@include absolute-horizontal-center(absolute);
    position: absolute;
    bottom: -8px;
    left: 20px;
    filter: drop-shadow(0 2px 2px rgba(19, 19, 19, 0.08));
  }
  &--right {
    line-height: 1.3;
    text-align: left;
    &::before{
      @include set-breakpoint(medium) {
        left: 120px !important;
      }
      @include set-breakpoint(medium,df) {
        left: 50% !important;
      }
    }
  }
}
