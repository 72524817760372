//Offer Card Block Component styles

//$c-offer-card--media-height: 372px !default;
$c-offer-card--media-height: 200px !default;
$c-offer-card--image-overlay: rgba(0, 0, 0, 0.3) !default;
$c-offer-card--z-index: 0 !default;
$offer-card-btn-color: $color-white !default;
$offer-card-info-color: $color-gray-darkest !default;
$offer-card-price-panel-bg: $color-gray-dull-light !default;
$offer-card-item-color: $color-gray-bright !default;
$offer-card-price-color: $color-gray-darkest !default;

.c-offer-card {

  .surf-star-ratings {
    //color: $color-content;
  }

  .surf-star-ratings__top {
    //color: $color-white;
  }
}

.top-offer{
  background: white;
  -webkit-box-shadow: 0 0 14px 0 #00000075;
}

.top-offer-panel{
  margin-left: 5px;
  margin-right: 5px;
}
.c-offer-card__image {
  @include flex-box-container(column);
  @include background-img(100%, $c-offer-card--media-height);
  position: relative;
  z-index: $c-offer-card--z-index;

  &::before{
    @include pseudoelem(absolute);
    z-index: $c-offer-card--z-index + 1;
    width: 100%;
    height: 100%;
  }
}

.c-offer-card__upper-notice {
  align-self: center;
  z-index: $c-offer-card--z-index + 2;
  @include set-font-size($base-font-size--small,16px);
  @include typography(base-1);
  font-weight: $font-weight--normal;
  color: $white;
  letter-spacing: normal;
  text-transform: uppercase;
  background-color: $color-grayish-dark;
  min-height: 36px;
  padding: $base-element-gap-tiny;
}

.c-offer-card__info {
  @include flex-box-container(column);
  z-index: $c-offer-card--z-index + 2;
  margin: auto $base-element-gap-small 0 $base-element-gap-small;
  font-weight: $font-weight--bold;
  color: $white;
  text-align: left;
  min-height: 145px;
}

.c-offer-card__location {
  @include set-font-size($base-font-size,20px);
  @include typography(base-3);
  color: $offer-card-info-color;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
  margin: $base-element-gap 0 5px 0;
}

.c-offer-card__title {
  @include set-font-size($base-font-size--medlarge,30px);
  @include typography(title-1);
  color: $color-gray-darkest;
  letter-spacing: 0.8px;
  margin-bottom: 5px;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.c-offer-card__price-card {
  @include flex-box-container(null, null, flex-end, center);
  width: 100%;
}

.c-offer-card__below-notice {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: $base-element-gap-tiny / 4;
  z-index: $c-offer-card--z-index + 2;
  @include set-font-size($base-font-size--small);
  @include flex-box-container(null, null, center, center);
  font-weight: $font-weight--strong;
  color: $white;
  background-color: $color-red-850;
  letter-spacing: 0.13px;
  line-height: 17px;
}

.c-offer-card__summary-panel {
  @include set-font-size($base-font-size--sm,20px);
  @include typography(base-1);
  color: $offer-card-info-color;
  margin: 0 $base-element-gap-small $base-element-gap-small $base-element-gap-small;
  letter-spacing: 0;
  opacity: 1;
}

.c-offer-card__price-panel {
  @include flex-box-container(column,null,center,flex-start);
/*  border-top: 1px solid $color-gray-light;
  border-bottom: 1px solid $color-gray-light;*/
  background-color: $offer-card-price-panel-bg;
  height: auto;
  padding: $base-element-gap $base-element-gap-small;
  width: 100%;
  margin: 0;
}

.c-offer-card__item {
  @include set-font-size($base-font-size--small,19px);
  @include typography(base-1);
  color: $offer-card-item-color;
  letter-spacing: 0;
  margin-right: $base-element-gap-tiny;
  text-decoration: none;
}

.call-us-ft {
  line-height: 20px;
  color: #333333;
  letter-spacing: -0.3px;
}

.c-offer-card__price {
  @include set-font-size($base-font-size--xx-mid-large,19px);
  @include typography(base-3);
  color: $offer-card-price-color;
  letter-spacing: 0;
  margin-right: $base-element-gap-tiny;
}

.c-offer-card__price-sub {
  @include set-font-size($base-font-size--medium,25px);
  @include typography(base-3);
  color: $offer-card-price-color;
  letter-spacing: 0;
  margin-right: 0px;
}

.c-offer-card__btn {
  @include set-font-size($base-font-size--small,16px);
  @include typography(base-3);
  color: $offer-card-btn-color;
  letter-spacing: normal;
  margin: $base-element-gap 0 $base-element-gap-tiny 0;
  height: 40px;
  width: 100%;
}

