// Component utility Mixings
@mixin type-ahead-input() {
  input[type=text] {
    font-size: $base-font-size;
    width: 100%;
    padding: $input-pd;
    outline: none;
    border: none;
    //box-shadow: 1px 1px 1px rgba($black,0.1);
    @content
  }
}

@mixin type-ahead-suggetion() {
  font-size: $base-font-size;
  outline: none;
  @content
}

@mixin type-ahead-options() {
  display: block;
  cursor: pointer;
  overflow: hidden;
  &:nth-last-child(1) {
    border: none;
  }
  @content
}

