/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */

body,html {
  min-height: 100%;
}

/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */

html {
  box-sizing: border-box;
  font-family: $font-family--primary;
  font-size: $em-base;
  -webkit-font-smoothing: antialiased;
}

* {

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }

}

body {
  color: $color-content;
  background: $body-color;
  font-size: $base-font-size;
  line-height: $base-line-height;
}


/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

body {
  a {
    cursor: pointer;
  }
}

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: $inuit-global-spacing-unit;
}



/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: $inuit-global-spacing-unit;
}

input::placeholder {
  opacity: 0.5;
}

/**
 * Undo the red box-shadow glow added by Firefox on invalid inputs.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-ui-invalid
 */
input:-moz-ui-invalid {
  box-shadow: none;
}
