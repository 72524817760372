/// Page title
.page-title {
  //margin-top: $base-element-gap-large;
  position: relative;
  padding: $base-element-gap-large 0;
}

.cart-page__close-btn {
  @include set-font-size($base-font-size--small);
  position: absolute;
  top: -20px;
  right: 0;
  height: auto;
  padding: 10px 0;

  @include set-breakpoint(small) {
    @include set-font-size($base-font-size);
  }

  &:hover {
    color: $color-content--dark;
  }
}

.page-title__main {
  @include set-font-size(25px, 30px);
  margin-bottom: $base-element-gap-tiny;
  font-weight: $font-weight--normal;

  @include set-breakpoint(x-small) {
    @include set-font-size(40px, 52px);
  }

  @include set-breakpoint(medium) {
    @include set-font-size(50px, 62px);
  }
}

.page-title__top-offers {
  @include set-font-size(25px, 30px);
  margin-bottom: $base-element-gap-tiny;
  color: #FFFFFF;
  font-weight: $font-weight--normal;
  text-shadow: 0 0 14px #00000075;
  @include set-breakpoint(x-small) {
    @include set-font-size(20px, 32px);
  }

  @include set-breakpoint(medium) {
    @include set-font-size(30px, 42px);
  }
}

.top-offers-frame {
  padding: 20px;
  background-color: #38424B;
}

.page-title__tag {
  display: block;
  @include set-breakpoint(medium) {
    max-width: 70%;
  }
}

// Section titles

.section-title {
  margin-bottom: $base-element-gap-tiny;
  @include set-breakpoint(medium) {
    margin-bottom: $base-element-gap;
  }
}

.section-title__text {
  @include set-font-size(24px, 34px);
}


/// Page content
.detail-page__page-content {
  position: relative;
  z-index: 5;
  top: -50px;
  padding-bottom: $base-element-gap-huge;

}

.detail-page__page-wrap {
  @include set-breakpoint(large) {
    @include grid-container();
    @include grid-template-columns(grid-repeat(2, 1fr), 60px);
    @include grid-autoflow(row, 2, 1);
  }
}

.detail-page__main-content {
  min-width: 0;
  //@include set-breakpoint(large) {
  //  max-width: 50%;
  //}
}

.detail-page__sidebar {
  min-width: 0;
  //@include set-breakpoint(large) {
  //  max-width: 50%;
  //  padding-left: $base-element-gap-huge;
  //}
}

/// Page section
.detail-page__section {
  //padding: $base-element-gap 0;
  margin-bottom: $base-element-gap;
}

.detail-page__section-title {
  margin-bottom: em($base-element-gap-tiny);
}

.detail-page__section-sub-title {
  margin-bottom: em($base-element-gap-tiny/2);
}

//section variants
.detail-page__section--package-summary {

  position: relative;
  height: 300px;

  .c-package-info {
    position: absolute;
    top: -90px;
    left: 20%;
    width: 270px;
  }
}


