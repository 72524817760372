@import "type-ahead-mix";
@import "surf-type-ahead-item.component";

$show-content: show-comp !default;

:host {
  .label {
    padding-bottom: 8px;
  }
}

surf-type-ahead {
  width: 100%;
  .surf-input {
    padding-top: 0;
    input {
      //padding-right: 20px !important;
      border-radius: 0 !important;
    }
  }
}

.clear-name {
  font-size: 16px !important;
  line-height: 25px !important;
  right: 5px !important;
  @include rtl-direction {
    right: auto !important;
    left: 5px !important;
  }
}

// Main Component
@mixin type-ahead--mix() {
  position: relative;
  &__input {
    @include type-ahead-input {
      border-radius: $default-radius;
      @include placeholder {
        color: lighten($content-color, 25%);
      }
    }
  }
}

.surf-type-ahead {
  @include type-ahead--mix;
}

.surf-type-ahead__suggetion {
  width: 100%;
  @include type-ahead-suggetion {
    @include set-font-size(14px);
    //z-index: 20;
    //position: absolute;
    //top: 26px;
    background: #fff;
    min-width: 220px;
    //border: 1px solid $color-alpha;
    width: auto;
    padding: 0;
    //min-height: 94px;
    border-radius: 0 !important;
    max-height: 350px;
    overflow: auto;
    margin-top: 0 !important;
    display: none;
  }
  @include set-breakpoint(medium) {
    @include styled-scroll-bar(10px);
  }

  &.active {
    display: block;
    opacity: 1;
    animation: $show-content $swift-ease-in-out-duration*2 $swift-ease-in-out-timing-function;
  }

  a.list-group-item {
    color: $color-alpha;
    .option-value {
      @include set-font-size(15px);
    }
  }
}

button.reset-button {
  position: absolute;
  top: 30%;
  right: 0px;
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  color: #757575;
  border: none;
  outline: none;
  cursor: pointer;
}


