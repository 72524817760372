
@include font-face(Kuoni-Feijoa, '../assets/fonts/Feijoa/KuoniFeijoa', $font-weight--normal);
@include font-face(Kuoni-Feijoa, '../assets/fonts/Feijoa/KuoniFeijoa-Bold', $font-weight--bold);
@include font-face('Magazine Grotesque', '../assets/fonts/MagazineGrotesque-Bold', $font-weight--normal,normal,otf);
@include font-face('Archivo-Bold', '../assets/fonts/archivo/Archivo-Bold', $font-weight--normal,normal,ttf);
@include font-face('Archivo-BoldItalic', '../assets/fonts/archivo/Archivo-BoldItalic', $font-weight--normal,normal,ttf);
@include font-face('Archivo-Italic', '../assets/fonts/archivo/Archivo-Italic', $font-weight--normal,normal,ttf);
@include font-face('Archivo-Light', '../assets/fonts/archivo/Archivo-Light', $font-weight--normal,normal,ttf);
@include font-face('Archivo-LightItalic', '../assets/fonts/archivo/Archivo-LightItalic', $font-weight--normal,normal,ttf);
@include font-face('Archivo-Regular', '../assets/fonts/archivo/Archivo-Regular', $font-weight--normal,normal,ttf);



//@include font-face(open_sansbold, '../assets/fonts/Open-sans/OpenSans-Bold-webfont', $font-weight--normal);
//@include font-face(open_sansregular, '../assets/fonts/Open-sans/OpenSans-Regular-webfont', $font-weight--normal);
//@include font-face(open_sanssemibold, '../assets/fonts/Open-sans/OpenSans-Semibold-webfont', $font-weight--normal);

p {
  @include set-font-size($base-font-size, $base-line-height);
  margin: 0.1em 0 1em;
}

a {
  color: $color-content--dark;
  text-decoration: underline;

  &:hover,
  &:active {
    color: $color-primary-dark;
  }

  //&:focus {
    //outline: $focus-outline;
    //outline-offset: $focus-spacing;
  //}

  //&:visited {
  //  color: $color-visited;
  //}
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  clear: both;
  color: $color-headings;
  font-family: $font-family--secondary;
  font-weight: $font-weight--strong;
  line-height: $heading-line-height;
}

h6 {
  font-family: $font-family--secondary;
}

h7 {
  display: block;
  clear: both;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  font-family: $font-family--primary;
  color: $color-gray;
  font-weight: $font-weight--strong;
}

/*h1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}*/

h4 {
  font-size: 18px;
}



// Remove user agent styles

cite,
var,
address,
dfn {
  font-style: normal;
}



// icon font define
@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?ai4ft4');
  src:  url('#{$icomoon-font-path}/icomoon.eot?ai4ft4#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/icomoon.woff2?ai4ft4') format('woff2'),
  url('#{$icomoon-font-path}/icomoon.ttf?ai4ft4') format('truetype'),
  url('#{$icomoon-font-path}/icomoon.woff?ai4ft4') format('woff'),
  url('#{$icomoon-font-path}/icomoon.svg?ai4ft4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@each $item-name, $icon in $icon-map {
  .icon-#{$item-name} {
    &::before {
      font-family: 'icomoon';
      content: $icon;
    }
  }
}


// title styling object
// combining with .t-heading makes a header title
%title-styling{
  margin: 0;
  font-family: $font-family--secondary;
  font-weight: $font-weight--bold;
  line-height: 1.5;
  letter-spacing: 0.23px;
  &.t-heading{
    margin: 1em 0 0.4em;
  }
}

.t1{
  @extend %title-styling;
  font-size: $base-font-size--xlarge;
  @include set-breakpoint(x-small){
    font-size: $base-font-size--xxlarge;
  }
}

.t2{
  @extend %title-styling;
  font-size: $base-font-size--large ;
  @include set-breakpoint(x-small){
    font-size: $base-font-size--xlarge;
  }
}

.t3{
  @extend %title-styling;
  font-size: $base-font-size--medium;
  @include set-breakpoint(x-small){
    font-size: $base-font-size--large;
  }
}

.t4{
  @extend %title-styling;
  font-size: $base-font-size;
  @include set-breakpoint(x-small){
    font-size: $base-font-size--medium;
  }
}

.t5{
  @extend %title-styling;
  font-size: $base-font-size--small;
  @include set-breakpoint(x-small){
    font-size: $base-font-size;
  }
}

.t6{
  @extend %title-styling;
  font-size: $base-font-size--small - 1;
  @include set-breakpoint(x-small){
    font-size: $base-font-size--small;
  }
}

%text-styling {
  font-family: $font-family--primary;
  letter-spacing: $letter-spacing--normal;
}

.huge-title{
  font-family: $font-family--secondary;
  font-size: $base-font-size--huge;
  color: $color-black-light;
  line-height: $base-line-height--xxxlarge;
}

.large-title{
  font-family: $font-family--secondary;
  font-size: $base-font-size--xxxlarge;
  color: $color-black-light;
  line-height: $base-line-height--xxxxlarge;
}

.s1{
  @extend %text-styling;
  @include set-font-size($base-font-size--large);
  @include set-breakpoint(x-small){
    font-size: $base-font-size--xlarge;
  }
}

.s2{
  @extend %text-styling;
  @include set-font-size($base-font-size--large);
}

.s3{
  @extend %text-styling;
  @include set-font-size($base-font-size--medium);
}

.s4{
  @extend %text-styling;
  @include set-font-size($base-font-size);
}

.s5{
  @extend %text-styling;
  @include set-font-size($base-font-size--small);
}

.s6{
  @extend %text-styling;
  @include set-font-size($base-font-size--xsmall);
}

.s7{
  @extend %text-styling;
  @include set-font-size($base-font-size--xxsmall);
}



// Typography text styles

// Default text style
.ts-default {
  color: $color-content;
}

.ts-dark {
  color: $color-content--dark;
}

// Primary text style
.ts-primary {
  color: $color-content--primary;
}

.ts-secondary {
  color: $color-content--secondary;
}

.ts-tertiary {
  color: $color-content--link;
}

.ts-link{
  color: $color-content--link;
}

.ts-important{
  color: $color-content--important;
}

.ts-muted {
  color: $color-content--muted;
}

.ts-disabled {
  color: $color-content--disabled;
}

.ts-light {
  color: $color-content--light;
}

.ts-wet {
   opacity: 0.5;
}

.ts-sub {
  margin-left: 5px;
  font-weight: normal;
}

.ts-success {
  color: $color-content--success;
}

.ts-uppercase {
  text-transform: uppercase;
}

.ts-strike {
  text-decoration: line-through;
}

.ts-normal {
  font-weight: $font-weight--normal;
}

.ts-strong {
  font-weight: $font-weight--strong;
}

.ts-bold {
  font-weight: $font-weight--bold;
  letter-spacing: 0.13px;
}
