/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually {
  @include hidden-visually();
}


/**
 * Hide visually and from screen readers.
 */

.u-hidden {
  display: none !important;
}


.u-hidden-sm {
  @include set-breakpoint(x-small, df){
    display: none !important;
  }
}

.u-visible-sm {
  @include set-breakpoint(x-small) {
    display: none !important;
  }
}

.u-hidden-md {
  @include set-breakpoint(medium, df){
    display: none !important;
  }
}

.u-visible-md {
  @include set-breakpoint(medium) {
    display: none !important;
  }
}

.u-hidden-lg {
  @include set-breakpoint(large, df){
    display: none !important;
  }
}

.u-visible-lg {
  @include set-breakpoint(large) {
    display: none !important;
  }
}
