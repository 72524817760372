// Error message atom

$a-error-msg--background: $color-red-550;
$a-error-msg--color: $color-white;
$a-error-msg--padding: 5px 15px;
$a-error-msg--font-size: 14px;


.a-error-msg {
  @include set-font-size($a-error-msg--font-size);
  display: none;
  width: auto;
  padding: $a-error-msg--padding;
  transform: translateZ(0);
  background: $a-error-msg--background;
  color: $a-error-msg--color;

  &::before {
    @include css-triangle(10px, $a-error-msg--background, top, ture);
    @include absolute-horizontal-center(absolute);
    bottom: 100%;
  }

  &.is-active {
    display: inline-block;
  }
}

.a-error-msg--box{
  display: block;

  &::before{
    display: none;
  }
}
