$surf-pagination-size: 30px;
$surf-pagination-font-size: 14px;
$surf-pagination-nav-size: 30px;
$surf-pagination--color: #67707A !default;
$surf-pagination--active-color: $color-primary !default;

.pagination-list {
  @include flex-box-container(null, null, null, center);
  @include set-font-size($surf-pagination-font-size, 17px);
  @include typography(base-2);
  margin: 0;
}


.pagination-list li {
  @include no-select();
  display: inline-block;
  margin: 0 5px 0 0;
  overflow: hidden;
  //border: 1px solid $border-color;
  border-radius: 20px;
  cursor: pointer;
}

.pagination-list a,
.pagination-list button {
  display: block;
  border-radius: 0;
  padding: 0 12px;
  color: $surf-pagination--color;
  line-height: $surf-pagination-size;
  text-decoration: none;
}

.pagination-list a:hover,
.pagination-list button:hover {
  background-color: #e6e6e6;
  //color: $color-alpha;
}

.pagination-list .current {
  padding: 0 12px;
  text-align: center;
  line-height: $surf-pagination-size;
  cursor: default;
  color: $color-white;
  font-weight: bold;
  background: $color-primary;
}

.pagination-list .disabled {
  //font-size: $surf-pagination-font-size;
  padding: 6px 12px;
  text-align: center;
  line-height: $surf-pagination-size;
  cursor: default;
  opacity: 0.4;
}

.pagination-list .disabled:hover {
  background-color: transparent;
}

.pagination-list .ellipsis::after {
  content: '…';
  padding: 0.1875rem 0.625rem;
  color: #0a0a0a;
}

.pagination-next a,
.pagination-next.disabled,
.pagination-previous a,
.pagination-previous.disabled{
  width: $surf-pagination-nav-size;
  height: $surf-pagination-nav-size;
  font-size: 22px;
}

.pagination-previous{
  border: 1px solid $surf-pagination--active-color;
  a {
    @include svg-icon(chevron_left, $surf-pagination--active-color);
    &:hover {
      background-color: transparent ;
    }
  }
  &.disabled{
    @include svg-icon(chevron_left);
    border: 1px solid $color-content;
  }
}


.pagination-next{
  border: 1px solid $surf-pagination--active-color;

  a {
    @include svg-icon(chevron_right, $surf-pagination--active-color);
    &:hover {
      background-color: transparent ;
    }
  }
  &.disabled{
    @include svg-icon(chevron_right);
    border: 1px solid $color-content;
  }
}


.pagination-list .show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
 .pagination-list .has-more{
   opacity: 1;
   margin: 0 0 0 -4px;
   @include rtl-direction{
     margin: 0 -4px 0 0;
   }
  
 }
