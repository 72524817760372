

@mixin spinner-trigger($trigger-bg, $radius:normal, $trigger: $trigger-size ) {
  &__trigger {
    @if $radius==round {
      border-radius: 50%;
    } @else {
      border-radius: $radius;
    }

    @include reset-form-elem;
    display: inline-block;
    width: $trigger;
    height: $trigger;
    text-align: center;
    //font-weight: bold;
    font-size: $trigger;
    line-height: $trigger;
    cursor: pointer;
    position: relative;
    @include contrasted($trigger-bg);
    @content;
    &.disable {
      opacity: 0.2;
      cursor: inherit;
    }
    &::before {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      transform:translate(-50%, -50%);
      line-height: 0;
    }
    &.sub {
      &::before {
        content: $sub;
        margin-top: -2px;
      }
    }

    &.add {
      &::before {
        content: $add;
      }
    }
  }
}

@mixin spinner-txt($trigger: $trigger-size, $font: $trigger-font) {
  &__txt {
    height: $trigger;
    line-height: $trigger;
    font-size: $font;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    min-width: 30px;
    max-width: 100px;
    width: 1px;
    background: transparent;
    border: none;
    outline: none;
  }
}


@mixin spinner-label() {
  &__label {
    @content
  }
}
