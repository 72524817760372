//Loading Page Block Component styles

.c-loading-section__title {
  @include set-font-size(50px, 70px);
  @include typography(title-3);
  margin-bottom: $base-element-gap-large;
}

.c-loading-section__deatils {
  @include set-font-size($base-font-size);
  font-weight: $font-weight--strong;
  color: $color-body-txt;
  letter-spacing: 0.15px;
  line-height: 26px;
  margin-bottom: $base-element-gap-large;
}

.c-loading-section__item {
  @include flex-box-container(row, null, null, center);
  @include set-font-size($base-font-size);
  color: $color-gray;
  height: 40px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 40px;
  &.is-completed {
    color: $color-body-txt-1;
    svg {
      color: $color-primary;
      display: block;
      @include set-font-size($base-font-size--large);
      margin-right: $base-element-gap
    }
    .loader-circle {
      display: none;
    }
  }
  span {
    margin-right: $base-element-gap-medium;
  }
  svg {
    display: none;
  }
}

.c-loading-section__icon {
  @include flex-box-container(null, null, end, center);
  width: 35px;
  height: 35px;
  margin-right: 30px;
}

.loader-circle {
  margin-left: 8px;
  font-size: 3px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: loader-circle 1.1s infinite ease;
  transform: translateZ(0);
}

//Loading Circle Spinner

@keyframes loader-circle {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #BBBFC3, 1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2), 2.5em 0em 0 0em rgba(187, 191, 195, 0.2), 1.75em 1.75em 0 0em rgba(187, 191, 195, 0.2), 0em 2.5em 0 0em rgba(187, 191, 195, 0.2), -1.8em 1.8em 0 0em rgba(187, 191, 195, 0.2), -2.6em 0em 0 0em rgba(187, 191, 195, 0.5), -1.8em -1.8em 0 0em rgba(187, 191, 195, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(187, 191, 195, 0.7), 1.8em -1.8em 0 0em #BBBFC3, 2.5em 0em 0 0em rgba(187, 191, 195, 0.2), 1.75em 1.75em 0 0em rgba(187, 191, 195, 0.2), 0em 2.5em 0 0em rgba(187, 191, 195, 0.2), -1.8em 1.8em 0 0em rgba(187, 191, 195, 0.2), -2.6em 0em 0 0em rgba(187, 191, 195, 0.2), -1.8em -1.8em 0 0em rgba(187, 191, 195, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(187, 191, 195, 0.5), 1.8em -1.8em 0 0em rgba(187, 191, 195, 0.7), 2.5em 0em 0 0em #BBBFC3, 1.75em 1.75em 0 0em rgba(187, 191, 195, 0.2), 0em 2.5em 0 0em rgba(187, 191, 195, 0.2), -1.8em 1.8em 0 0em rgba(187, 191, 195, 0.2), -2.6em 0em 0 0em rgba(187, 191, 195, 0.2), -1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(187, 191, 195, 0.2), 1.8em -1.8em 0 0em rgba(187, 191, 195, 0.5), 2.5em 0em 0 0em rgba(187, 191, 195, 0.7), 1.75em 1.75em 0 0em #BBBFC3, 0em 2.5em 0 0em rgba(187, 191, 195, 0.2), -1.8em 1.8em 0 0em rgba(187, 191, 195, 0.2), -2.6em 0em 0 0em rgba(187, 191, 195, 0.2), -1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(187, 191, 195, 0.2), 1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2), 2.5em 0em 0 0em rgba(187, 191, 195, 0.5), 1.75em 1.75em 0 0em rgba(187, 191, 195, 0.7), 0em 2.5em 0 0em #BBBFC3, -1.8em 1.8em 0 0em rgba(187, 191, 195, 0.2), -2.6em 0em 0 0em rgba(187, 191, 195, 0.2), -1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(187, 191, 195, 0.2), 1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2), 2.5em 0em 0 0em rgba(187, 191, 195, 0.2), 1.75em 1.75em 0 0em rgba(187, 191, 195, 0.5), 0em 2.5em 0 0em rgba(187, 191, 195, 0.7), -1.8em 1.8em 0 0em #BBBFC3, -2.6em 0em 0 0em rgba(187, 191, 195, 0.2), -1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(187, 191, 195, 0.2), 1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2), 2.5em 0em 0 0em rgba(187, 191, 195, 0.2), 1.75em 1.75em 0 0em rgba(187, 191, 195, 0.2), 0em 2.5em 0 0em rgba(187, 191, 195, 0.5), -1.8em 1.8em 0 0em rgba(187, 191, 195, 0.7), -2.6em 0em 0 0em #BBBFC3, -1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(187, 191, 195, 0.2), 1.8em -1.8em 0 0em rgba(187, 191, 195, 0.2), 2.5em 0em 0 0em rgba(187, 191, 195, 0.2), 1.75em 1.75em 0 0em rgba(187, 191, 195, 0.2), 0em 2.5em 0 0em rgba(187, 191, 195, 0.2), -1.8em 1.8em 0 0em rgba(187, 191, 195, 0.5), -2.6em 0em 0 0em rgba(187, 191, 195, 0.7), -1.8em -1.8em 0 0em #BBBFC3;
  }
}
