//Booking Terms and Conditions Component styles

.c-text-scroller__header {
  @include set-font-size(25px, 30px);
}

.c-text-scroller__body{
  @include styled-scroll-bar(5px);
  @include typography(base-1);

  margin-top: $base-element-gap;
  padding-right: $base-element-gap-medium;
  //text-align: justify;

  @include set-breakpoint(large) {
    @include inner-scroll(y);
    max-height: 600px;
  }

  h3,
  h4,
  h5{
    @include typography(base-2);
    @include set-font-size($base-font-size--small);
  }

  p{
    @include set-font-size($base-font-size--small);
  }

}

.c-text-scroller__title{
  text-transform: uppercase;
}
