//Suffix Card Block Component styles

$c-suffix-card--media-height: 372px !default;
$c-suffix-card--image-overlay: rgba(0, 0, 0, 0.3) !default;
$c-suffix-card--z-index: 0 !default;
$logo-primary-white: 'https://www.kuoni.co.uk/content/img/header/KUONI_logo-white.svg';
$logo-size: (
        width: 160px,
        height: 45px
);

.c-suffix-card {

  .surf-star-ratings {
    color: $color-content;
  }

  .surf-star-ratings__top {
    color: $color-white;
  }
}

.c-suffix-card__image {
  @include flex-box-container(column);
  @include background-img(100%, $c-suffix-card--media-height);
  position: relative;
  z-index: $c-suffix-card--z-index;

  &::before{
    @include pseudoelem(absolute);
    z-index: $c-suffix-card--z-index + 1;
    width: 100%;
    height: 100%;
    background-color: $c-suffix-card--image-overlay;
  }
}

.c-suffix-card__upper-notice {
  align-self: center;
  z-index: $c-suffix-card--z-index + 2;
  @include set-font-size($base-font-size--small);
  font-weight: $font-weight--normal;
  color: $white;
  letter-spacing: 0.13px;
  line-height: 17px;
  background-color: $color-bg-dark;
  padding: $base-element-gap-tiny;
}

.c-suffix-card__info {
  @include flex-box-container(column);
  z-index: $c-suffix-card--z-index + 2;
  margin: auto $base-element-gap $base-element-gap-large;
  font-weight: $font-weight--bold;
  color: $white;
  text-align: left;
}

.c-suffix-card__location {
  @include set-font-size($base-font-size--medium);
  letter-spacing: 0.16px;
  line-height: 22px;
  text-transform: uppercase;
}

.c-suffix-card__title {
  @include set-font-size($base-font-size--xlarge);
  @include typography(title-3);
  letter-spacing: 0.23px;
  line-height: 30px;
}

.c-suffix-card__price-card {
  @include flex-box-container(null, null, null, center)
}

.c-suffix-card__below-notice {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: $base-element-gap-tiny / 4;
  z-index: $c-suffix-card--z-index + 2;
  @include set-font-size($base-font-size--small);
  @include flex-box-container(null, null, center, center);
  color: $white;
  //background-color: $color-red-850;
  letter-spacing: 0.13px;
  line-height: 17px;
}

.c-suffix-card__summary-panel {
  padding: $base-element-gap-small 0;
  color: $color-body-txt-1;
  @include set-font-size($base-font-size);
  font-weight: $font-weight--normal;
  letter-spacing: 0.15px;
  line-height: 26px;
}

.c-suffix-card__price-panel {
  @include flex-box-container(null, null, space-between, center);
  border-top: 1px solid $color-gray-light;
  border-bottom: 1px solid $color-gray-light;
  height: 54px;
}

.c-suffix-card__item {
  margin-right: $base-element-gap-tiny;
  font-size: $base-font-size--small;
}

.c-suffix-card__price {
  font-size: $base-font-size--xlarge;
}

.c-suffix-card__btn {
  height: 40px;
  font-size: $base-font-size--small;
  padding: $base-element-gap-tiny;
}

.a-logo-suffix {
  background: {
    image: url($logo-primary-white);
    repeat: no-repeat;
    position: center;
    size: contain;
    color: $color-grayish-dark;
  };
  @include get-elements-size($logo-size);
}

.logo-wrapper{
  background-color: $color-grayish-dark;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

