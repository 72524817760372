//Book and Confidence Block Component styles

.c-book-confidence {
  @include flex-box-container(column, null, null, null);
  border: $global-border-style--light;
  margin-bottom: $base-element-gap;
  padding: 0 $base-element-gap;
}

.c-book-confidence__details {
  @include flex-box-container(column, null, null, null);
  align-self: center;
}

.c-book-confidence__icons {
  @include flex-box-container(row, null, center, center);
  margin-top: 10px;
}

.c-book-confidence__brand {
  flex: 1 0 33%;
  img {
    max-width: 100%;
  }
}

