

@mixin chip-mix($height:null, $bg:$chips-bg){
  @if $height == null {
    height: inherit;
    line-height: inherit;
    border-radius: inherit;
  } @else {
    height: $chips-height;
    line-height: $chips-height;
    border-radius: $chips-height/2;
  }
  font-size: rem($chip-font-size);
  position: relative;
  display: inline-block;
  //max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 $chip-padding;
  transition: $swift-ease-in-duration $swift-ease-in-out-timing-function;
  @include contrasted($bg);
  //@include no-select;
  @content;

  &.selected {
    @include contrasted($chips-active-bg)
  }
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}
