//* ========================================================================
//   #CLEARFIX
//   ======================================================================== */

// .usage {
//   @include inuit-clearfix();
// }

@mixin clearfix() {

  &:after {
    content: "" !important;
    display: block !important;
    clear: both !important;
  }

}
