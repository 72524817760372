$a-ul-list--font-color: $color-black !default;
$a-ul-list--background: $color-white;
$a-ul-list--background--parent: $color-gray-light;
$a-ul-list--parent--font-weight: $font-weight--extra-bold;

.a-ul-list{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item{
    padding: $base-element-gap-small $base-element-gap;
    border-bottom: $global-border-style--light;
    background: $a-ul-list--background;
    color: $a-ul-list--font-color;

    &--parent{
      pointer-events: none;
      background: $a-ul-list--background--parent;
      color: $a-ul-list--font-color;
      font-weight: $a-ul-list--parent--font-weight;
    }

    &:last-child{
      border-bottom: 0;
    }
  }
}
