$surf-modal-max-width: 720px;

.back-drop {
  position: fixed; /* Stay in place */
  z-index: $z-index-overlay * 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: $body-color;
  -webkit-overflow-scrolling: touch;

  //@include styled-scroll-bar(15px);

  @include set-breakpoint(medium){
    background-color: rgba(black, 0.5); /* Black w/ opacity */
  }

  .btn-back{
    border: none;
    color: $color-gray;
    padding: 0 20px;
    display: block;
    cursor: pointer;

    @include rtl-direction{
      @include rtl-flip;
    }

    @include set-breakpoint(medium){
      display: none;
    }
  }

  .modal-content{
    position: relative;
    max-width:$surf-modal-max-width;
    margin:0;
    height: auto;
    padding-bottom: 100px;
    box-shadow: none;
    border: none;
    @include set-breakpoint(medium){
      margin: 100px auto 50px;
      padding-bottom: 0;
    }
    .close{
      opacity: 1;
    }
  }

}

.content {
  z-index: 101;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  right: -20px;
  padding: 20px;
  //font-weight: bold;
  display: none;
  @include set-breakpoint(medium){
    display: block;
    position: absolute;
    right: -21px;
    top: -55px;
    @include rtl-direction{
      right: auto;
      left: -21px;
    }
  }

  &:hover, &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  i{
    display: block;
    background-image: url('/assets/theme/images/cancel.png');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
  }
}




