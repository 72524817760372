 $surf-calendar-color: $color-content;

@mixin calender--nav {
  &__nav {
    position: relative;

    .pre,
    .nxt {
      opacity: 0.8;
      @include reset-form-elem;
      position: absolute;
      background: none;
      @include no-select;
      cursor: pointer;
      font-size: $base-font-size;
      transition: $swift-ease-in-out;
      transform: translateY(5px);

      @include only-ie{
        min-height: 25px;
        top: -2px;
      }

      i {
        display: none;
      }

      & > span {
        display: none;
        opacity: 0.4;
        padding: {
          top: 1px;
          left: 5px;
          right: 5px;
        };
        transition: $swift-ease-in-out;
      }

      &:hover, &:focus {
        opacity: 1;
        span {
          padding: {
            left: 8px;
            right: 8px;
          };
        }
      }

      &.button-visible{
        display: block;
        @include set-breakpoint(medium){
          display: none;
        }
      }




    }

    // Arrow Define as the looks
    .pre {
      @include svg-icon(arrow_back);
      @include set-font-size(20px);
      left: 0;
      width: 20px;
      height: 20px;
      margin-top: 5px;
      @include rtl-direction{
        right: 0;
        left: auto;
        @include rtl-flip;
      }
      @include set-breakpoint(medium){

        left: 10px;
        margin-top: 0;
      }

    }
    .nxt {
      @include svg-icon(arrow_forward);
      @include set-font-size(20px);
      right: 0;
      width: 20px;
      height: 20px;
      margin-top: 5px;
      @include rtl-direction{
        left: 0;
        right: auto;
        @include rtl-flip;
      }
      @include set-breakpoint(medium){

        right: 10px;
        margin-top: 0;
      }
    }
  }
}


// Calender Mix
@mixin calendar--mix($label-c: $calender-label-color, $selected: $color-secondary) {
  @include no-select;


  &__view {
    .title {
      text-align: center;
      margin-bottom: $base-element-gap-tiny;
      label {
        cursor: pointer;
        @include no-select;
      }
    }
    .const-raw {
      //min-width: 320px;
      min-width: $calender-min-size;
      @include flex-box-wrapper(wrap);
      justify-content: center;
      &__date {
        @include flex-box-item(center, center, column, 0 14.28%);
        text-align: center;
        position: relative;
        border:{
          left: 1px solid $border-color;
          bottom: 1px solid $border-color;
        };
        @include rtl-direction{
          border:{
            left: none;
            right: 1px solid $border-color;
          };
        }
        @include set-breakpoint(medium) {
          height: $date-span-size;
          @include flex-box-item(center, center, column, 0 $date-span-size);
        }
        &:last-child{
          border:{
            right: 1px solid $border-color;
          };
        }
        &.disabled {
          //opacity: 0.4;
          span {
            color: lighten($surf-calendar-color, 30%) !important;
          }
        }
        &.not_available_date{
          span {
            color: lighten($surf-calendar-color, 30%) !important;
          }
        }
        span {
          display: inline-block;
          cursor: pointer;
          box-shadow: inset 0 0 2px rgba($selected,0);
          transition: $swift-ease-in;
          text-align: center;
        }

        &.fadeOut {
          //opacity: $fade-out-factor;
          span {
            color: lighten($surf-calendar-color, 30%) !important;
          }
        }
        &.current-date {
          span {
            //font-weight: bold;
            //@include contrasted($current-day-bg)
          }
          //&:after {
          //  //border-radius: $round-radius;
          //  content: "";
          //  width: 5px;
          //  height: 5px;
          //  position: absolute;
          //  right: 5px;
          //  top: 5px;
          //  background: $current-day;
          //}
        }

        &:hover, &:focus {
          span {
            box-shadow: inset 0 0 2px rgba($selected, 1);
          }
        }
        &.selected {
          //border-color: $solid-blue;
          //border: none;
          span {
            @include contrasted($color-alpha);
            box-shadow: inset 0 0 1px $color-white;
          }
        }
        &.start-date,
        &.end-date{
          //border-color: $color-alpha;
          span{
            background: $color-alpha;
            //box-shadow: 0 0 1px $color-alpha;
            //position: relative;
            //&:after {
            //  @include pseudoelem(absolute);
            //  border: 1px solid $color-alpha;
            //  top: -1px;
            //  bottom: -1px;
            //  left: -1px;
            //  right: -1px;
            //}
          }
        }
        //&.start-date {
        //  span {
        //    &:after {
        //      right: 0;
        //    }
        //  }
        //}
        //&.end-date {
        //  span {
        //    &:after {
        //      left: 0;
        //    }
        //  }
        //}
      }

      &.labels {
        color: $label-c;
        @include set-font-size(12px);

        .const-raw__date {
          border: none;
        }

        & + .const-raw {

          .const-raw__date {
            border-top: 1px solid $border-color;
          }
        }
      }

      // Dates Calender
      // This styles will represent date calendar as they suite to the calender component

      &.dates {
        .const-raw__date {
          span {
            text-align: center;
            height: $date-span-size;
            width: 100%;
            line-height: $line-height--if-round-coners;
            border-radius: $date-item-radius;
            //font-weight: bold;
            @include set-breakpoint(medium) {
              //width: $date-span-width-size;
            }
          }
        }
        //&:last-child{
        //  .const-raw__date {
        //    border-bottom: none;
        //  }
        //}

      }

      // Months Calender
      // This styles will represent month calendar as they suite to the calender component

      &.months {
        justify-content: space-around;
        .current-month {
          span {
            //font-weight: bold;
            @include contrasted($current-day-bg)
          }
        }
        .const-raw__date {
          flex: 1;
          height: auto;
          span {
            text-align: center;
            width: 100%;
            display: block;
            padding: {
              top: $date-span-size/2;
              bottom: $date-span-size/2;
            };
            vertical-align: middle;
          }
        }

        &.multiple-selects {
          .const-raw__date {
            span {
              @include chip-mix($chips-height) {
                height: $chips-height;
                line-height: $chips-height;
                border-radius: $chips-height/2;
              }
            }
            &.selected {
              span {
                @include contrasted($selected)
              }
            }
          }
        }
        &:last-child{
          .const-raw__date {
            border-bottom: none;
          }
        }
      }

      // Years Calender
      // This styles will represent years calendar as they suite to the calender component
      &.years {
        justify-content: space-around;
        .current-year {
          span {
            text-align: center;
            //font-weight: bold;
            @include contrasted($current-day-bg)
          }
        }
        .const-raw__date {
          height: auto;
          flex: 1;
          span {
            text-align: center;
            width: 100%;
            display: block;
            padding: {
              top: $date-span-size/2;
              bottom: $date-span-size/2;
            };
            vertical-align: middle;
          }
        }
        &:last-child{
          .const-raw__date {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@include keyframe(show-calender){
  0% {
    display: block;
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.surf-calendar-wrapper{
  background: white;
}

.surf-calendar-comp {
  @include calendar--mix();
  @include calender--nav;
  overflow: hidden;
  float: left;
  min-width: $calender-min-size;

  &__view {
    background: white;
    //padding: 20px 20px 15px;
    padding: 15px 5px;
    span {
      color: $surf-calendar-color;
      mark {
        background: transparent;
      }
    }
    label {
      color: $color-alpha;
      mark {
        background: transparent;
        color: $color-alpha;
      }
    }
  }

  &--hide {
    display: none;
    opacity: 0;
  }
  &--show {
    //@include animation($swift-ease-in-out-timing-function, $swift-ease-in-out-duration, show-calender);
  }
}

.date-picker {
  position: relative;
  .surf-calendar-comp.toggle {
    box-shadow: 0 8px 25px hsla(0,0%,46%,0.4);
    top:53px;
    width: 100%;
    @include set-breakpoint(medium){
      width: auto;
    }
  }
  .surf-input input{
    //padding-bottom: 6px;
  }

  //TODO: remove below after fixing calander error handling
  &.has-error{
    .surf-input {
      .surf-error{
        color: $color-danger;
      }
      input{
        border-color:$color-danger !important;
        border-width: medium;
      }
    }
  }
  
}

.toggle {
  min-width: auto !important;
}

.surf-calendar-duo {
  @include flex-box-container(null, null, space-evenly);
}

.surf-calendar-duo{

  .surf-calendar-comp span {
    color: $surf-calendar-color;
    mark {
      background: transparent;
    }
  }

  label {
    color: $color-alpha;
    mark {
      background: transparent;
      color: $color-alpha;
      @include set-font-size(18px);
    }
  }

  .surf-calendar-comp {
    float: none;
    width: 100%;
    margin: 0 0 10px 0;
    @include rtl-direction{
      float: none ;
    }
    @include set-breakpoint(medium) {
      float: right;
      width: auto;
      margin: 0;
      &:first-child {
        float: left;
      }
    }

    &__nav{
      .nxt,
      .pre{
        margin-top: 0;
        padding: 10px;
        @include set-breakpoint(medium){
          padding: 0;
        }

        @include rtl-direction{
          @include rtl-flip();
        }

      }
    }

    .surf-calendar-comp__view {
      margin: 0;
      padding: 25px 15px;
      background: $color-white;

      @include set-breakpoint(medium) {
        margin: 0;
      }

      label{
        color: $color-alpha;
        @include set-font-size(18px);
        font-weight: $font-weight--bold;
      }
    }
    .const-raw{
      @include flex-box-container(row,wrap,center);
      span{
        @include set-font-size(16px);
      }
    }

    .const-raw__date.selected{
      span{
        color:$color-white;
      }
    }

    &#calendar-right{
      display: none;
      @include set-breakpoint(medium){
        display: block;
      }

      .surf-calendar-comp__view {
        padding-left: 0;
      }
      @include rtl-direction{
        float: left;
      }
    }

    &#calendar-left{
      .surf-calendar-comp__view {
        @include set-breakpoint(medium) {
          padding-right: 0;
        }
      }

      @include rtl-direction{
        @include set-breakpoint(medium){
          float: right;
        }

      }
    }
  }
}
