@import "surf-spinner-mix";

@mixin spinner--mix($trigger-bg, $trigger: 1.5em, $seprator: $content-color) {
  .spinner {
    @include spinner-trigger($trigger-bg, round);
    @include spinner-txt();
    @include spinner-label {
      padding-bottom: 5px;
    }
  }
}

.surf-spinner {
  @include spinner--mix($color-alpha, $white);

  .spinner__wrap {
    @include flex-box-container();
  }

  .spinner__trigger {
    color: $color-black;
    background: $color-white;
    border: 1px solid $color-black;
  }
}


