@import "type-ahead-mix";

surf-type-ahead-item {
  display: block;
  transition: $swift-ease-in;
  @include type-ahead-options {
    color: $content-color;
    padding: $input-pd;
    border-bottom: 1px solid lighten($content-color, 48%);
    .region {
      color: lighten($content-color, 40%);
    }
    &:hover, &:focus {
      background: #e9edf0 !important;
    }
  }

  &.focus {
    background: lighten($content-color, 50%);
  }
}
