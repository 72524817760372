$surf-dropdown-max-list-height: 350px;

%drop-down-trigger {
  
  //background:
  //        linear-gradient(45deg, transparent 50%, $color-gray 51%) calc(100% - 14px) calc(50% + 4px) / 4px 4px,
  //        linear-gradient(315deg, transparent 50%, $color-gray 51%) calc(100% - 10px) calc(50% + 4px) / 4px 4px,
  //        linear-gradient(225deg, transparent 50%, $color-gray 51%) calc(100% - 10px) calc(50% - 4px) / 4px 4px,
  //        linear-gradient(135deg, transparent 50%, $color-gray 51%) calc(100% - 14px) calc(50% - 4px) / 4px 4px;
  //background-repeat: no-repeat;

  @include svg-icon(expand, $color-content);
  background-position: calc(100% - 8px) center;
}

// Drop down trigger mixing
@mixin dropdown-trigger {
  position: relative;
  &__trigger {
    @include reset-form-elem;
    font-size: rem($base-font-size);
    cursor: pointer;
    position: relative;
    @extend %drop-down-trigger;
    background-color: transparent;
    @content;
  }
}

// Drop down option mixing
@mixin dropdown-options($animation: show-comp) {
  &__options {
    //border-radius: $default-radius;
    font-size: rem(14px);
    z-index: $z-index-trigger;
    position: absolute;
    //@include styled-scroll-bar;
    @content;
    ul {
      @include reset-box-model;
      list-style: none;
      max-height: $surf-dropdown-max-list-height;
      overflow: auto;

      li {
        transition: $swift-ease-out;
        label {
          cursor: pointer;
          display: block;
          color: $color-gray;
        }
        &:hover, &:focus {
          background: #eee;
        }
      }
    }
    &--hide {
      display: none;
      opacity: 0;
    }
    &--show {
      animation: $animation $swift-ease-in-out-duration*2 $swift-ease-in-out-timing-function;
    }
  }
}
