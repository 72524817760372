///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.
//// @type Spacing

$breakpoints: (
        'x-small'    : null,
        'small'      : 580px,   //Mobile
        'medium'     : 768px,   //Tablet
        'x-medium'   : 980px,
        'large'      : 1024px,  //Laptop
        'x-large'    : 1280px   //Desktop
) !default;

// Element spacing


$base-element-gap-tiny:      10px !default;
$base-element-gap-small:     15px !default;
$base-element-gap:           20px !default;
$base-element-gap-compact:   25px !default;
$base-element-gap-medium:    30px !default;
$base-element-gap-large:     40px !default;
$base-element-gap-xlarge:    50px !default;
$base-element-gap-huge:      60px !default;

// Border styles
$global-border-style: 1px solid $color-gray !default;
$global-border-style--light: 1px solid $color-gray-lighter-ku !default;


// Box shadows
$global-box-shadow: 0 4px 8px 4px rgba(196, 196, 196, 0.5) !default;
$global-box-shadow--hover: 0 0 4px 0 rgba(0, 0, 0, 0.6) !default;
$global-box-shadow--selected: 0 2px 20px 0 rgba(198, 196, 196, 0.5) !default;
$global-box-shadow-new: 0 5px 20px 10px rgba(196, 196, 196, 0.5) !default;

// Standardise some UI treatments.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

$global-modal-view: true !default;


// Overlay
$gloabal-overlay-style: rgba(77, 92, 116, 0.8) !default;


