//Contact-section Component styles

.c-contact-section {
  border: $global-border-style--light;
  line-height: 1.1;
}

.c-contact-section__icon {
  @include flex-box-container(null, null, center, center);
  @include set-font-size(120px);
}

.c-contact-section__details {
  max-width: 43%;
}

.c-contact-section__item {
  @include flex-box-container(null, null, center, center);
  border-bottom: $global-border-style--light;
  padding: 5px;
  height: auto;
  text-decoration: none;

  &:hover {
    color: $color-content;
  }
}

.c-contact-section__item-title {
  line-height: 1.1;
}

.c-contact-section__item-text {
  color: $color-content;

  a{
    color: inherit;
    text-decoration: none;
  }
}

.c-contact-section__extra-info {
  @include flex-box-container(column);
  @include set-font-size($base-font-size--small, 16px);
  margin-top: $base-element-gap-tiny;
}
