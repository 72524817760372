/*surf-gallery*/

.surf-gallery {
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.surf-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    @include pseudoelem(absolute);
    display: none;
    z-index: 10;
    top: 0;
    bottom: 0;
    width: 40px;
    background: #ffffff82;
    box-shadow: 0 0 50px 20px #ffffff82;
  }

  &.has-left {
    &::before {
      display: block;
      left: 0;
    }
  }

  &.has-right {
    &::after {
      display: block;
      right: 0;
    }
  }

}

.surf-list:focus {
  outline: none;
}

.surf-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.surf-gallery .surf-track,
.surf-gallery .surf-list {
  transform: translate3d(0, 0, 0);
}

.surf-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  @include flex-box-container();
}

//.surf-track:before,
//.surf-track:after {
//  display: table;
//  content: '';
//}
//.surf-track:after {
//  clear: both;
//}
.surf-loading .surf-track {
  visibility: hidden;
}

.surf-slide {
  //float: left;
  height: 100%;
  min-height: 1px;
}

.surf-gallery {
  width: 100%;
  /*margin: -5px;*/
  //float: left;
  position: relative;
}
.surf-gallery__item {
  //width: 349px
  width: 25%;
  max-width: 200px;
}
@media screen and (max-width: 700px) {
  .surf-gallery__item {
    //width: 149px
    width: 25%;
    max-width: 200px;
  }
}
.surf-gallery__item--first {
  //width: 702px;
  width: 50%;
  max-width: 400px;
}

@media screen and (max-width: 700px) {
  .surf-gallery__item--first {
    //width: 300px;
    width: 50%;
  }
}

.surf-gallery figure {
  margin: 0;
  display: block;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% !important;
}
.surf-gallery figure figcaption {
  display: none
}
.surf-gallery img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  display: block
}
.surf-gallery .surf-slide > div {
  //float: left;
  margin: 5px;
  @include flex-box-container(null, null, center, center);
  background: #eee;
  margin-bottom: 10px;

  &:last-child{
    margin-bottom: 0;
  }
}

@media screen and (max-width: 700px) {
  .surf-gallery .surf-slide > div {
    margin: 1px
  }
}

.surf-gallery .surf-arrow {
  position: absolute;
  top: 0;
  z-index: 3;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.4;
  }

}

.surf-gallery .surf-arrow.surf-next {
  right: 0;
}

.surf-gallery .surf-arrow.surf-prev {
  left: 0;
 }

// Gallery beryl

.surf-gallery__controls {
  @include flex-box-container(null, null, space-between, center);
  margin-top: $base-element-gap-tiny;
  padding: $base-element-gap-tiny / 2;
}

.surf-gallery__pagination {
  @include flex-box-container(null, null, null, center);
}

.surf-gallery__pagination-item {
  @include css-circle(7px, $color-gray-light);
  margin-right: $base-element-gap-tiny / 2;

  &.is-current{
    width: 10px;
    height: 10px;
    background: $color-gray;
  }
}

.surf-gallery__nav {
  @include set-font-size($base-font-size--xxlarge);
  position: relative;
  width: 60px;
  height: 30px;


}

/* Gallery Image Expander */

.surf-image-expander {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
}
.surf-image-expander--open {
  display: block;
}
.surf-image-expander--result-page {
  .surf-image-expander__bg {
    background: rgba(0, 0, 0, .85);
  }
  .surf-image-expander__img {
    max-width: 80%;
  }
  .surf-image-expander__top-bar, .surf-image-expander__caption {
    background-color: transparent;
  }
}
.surf-image-expander__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $black;
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  will-change: opacity;
}
.surf-image-expander__bg {
  will-change: opacity;
  -webkit-transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
}
.surf-image-expander__scroll-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.surf-image-expander__container {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.surf-image-expander__container,
.surf-image-expander__img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.surf-image-expander__container{
  -webkit-backface-visibility: hidden;
}
.surf-image-expander__item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding: 22px 0;
}
.surf-image-expander__img {
  position: absolute;
  width: auto;
  height: auto;
}

.surf-image-expander__img {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  overflow: auto;
}

.surf-image-expander__button {
  width: 44px;
  height: 44px;
  position: relative;
  background: none;
  cursor: pointer;
  overflow: visible;
  -webkit-appearance: none;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  float: right;
  opacity: 0.75;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.surf-image-expander__button:focus,
.surf-image-expander__button:hover {
  outline: none;
  opacity: 1;
}
.surf-image-expander__button:active {
  outline: none;
  opacity: 0.9;
}
.surf-image-expander__button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.surf-image-expander__button,
.surf-image-expander__button--arrow--left:before,
.surf-image-expander__button--arrow--right:before {
  background: url('../assets/icons/image-gallery/default-skin.png') 0 0 no-repeat;
  background-size: 264px 88px;
  width: 44px;
  height: 44px;
}

.surf-image-expander__button--close {
  background-position: 0 -44px;
}
.surf-image-expander__button--share {
  background-position: -44px -44px;
}
.surf-image-expander__button--fs {
  display: none;
}
.surf-image-expander--supports-fs .surf-image-expander__button--fs {
  display: block;
}
.surf-image-expander--fs .surf-image-expander__button--fs {
  background-position: -44px 0;
}
.surf-image-expander__button--zoom {
  display: none;
  background-position: -88px 0;
}
.surf-image-expander--zoom-allowed .surf-image-expander__button--zoom {
  display: block;
}
.surf-image-expander--zoomed-in .surf-image-expander__button--zoom {
  background-position: -132px 0;
}

.surf-image-expander__button--arrow--left,
.surf-image-expander__button--arrow--right {
  background: none;
  top: 50%;
  margin-top: -50px;
  width: 70px;
  height: 100px;
  position: absolute;
}
.surf-image-expander__button--arrow--left {
  left: 0;
}
.surf-image-expander__button--arrow--right {
  right: 0;
}
.surf-image-expander__button--arrow--left:before,
.surf-image-expander__button--arrow--right:before {
  content: '';
  top: 35px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 30px;
  width: 32px;
  position: absolute;
}
.surf-image-expander__button--arrow--left:before {
  left: 6px;
  background-position: -138px -44px;
}
.surf-image-expander__button--arrow--right:before {
  right: 6px;
  background-position: -94px -44px;
}
.surf-image-expander__counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.surf-image-expander__counter {
  position: absolute;
  left: 0;
  top: 0;
  height: 44px;
  font-size: 13px;
  line-height: 44px;
  color: $white;
  opacity: 0.75;
  padding: 0 10px;
}
.surf-image-expander__caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 44px;
}
.surf-image-expander__caption small {
  font-size: 11px;
  color: #BBB;
}
.surf-image-expander__caption__center {
  text-align: center;
  max-width: 420px;
  margin: 0 auto;
  font-size: 1.125rem;
  font-family: arial, helvetica, sans-serif;
  font-weight: 400;
  padding: 10px;
  line-height: 1.125rem;
  color: #eaeae9
}
.surf-image-expander__caption--empty {
  display: none;
}

.surf-image-expander__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: 1550;
}
.surf-image-expander__top-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 44px;
  width: 100%;
}

.surf-image-expander--has_mouse .surf-image-expander__button--arrow--left,
.surf-image-expander--has_mouse .surf-image-expander__button--arrow--right {
  visibility: visible;
}
.surf-image-expander__top-bar,
.surf-image-expander__caption {
  background-color: rgba(0, 0, 0, 0.5);
}


